.host {
  width: 100%;
  flex-shrink: 0;
  padding: var(--frame-padding);
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.logoLink {
  display: block;
}

.logo {
  width: 6rem;
  margin-bottom: 1rem;
  /* transition: filter 0.3s cubic-bezier(0.19, 1, 0.22, 1); */
}

.logoLink:hover .logo {
  filter: invert(50%) sepia(70%) saturate(400%) hue-rotate(310deg) brightness(100%) contrast(80%);
}

.twitterLink,
.instagramLink {
  display: inline-block;
  vertical-align: middle;
  width: 1rem;
  height: 1rem;
  position: relative;
  margin-left: 0.5rem;
}

.instagramIcon {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.instagramLink:hover .instagramIcon:first-child,
.instagramIcon:last-child {
  visibility: hidden;
}

.instagramLink:hover .instagramIcon:last-child {
  visibility: initial;
}

.twitterLink svg {
  fill: currentcolor;
}

.twitterLink:hover {
  color: rgb(29, 155, 240);
}

.categories {
  width: 100%;
  margin-top: 4rem;
  font-size: 1.25rem;
  line-height: 1.25;
}

.about {
  margin-top: 1rem;
  font-size: 1.25rem;
}

.about ul {
  line-height: 1.5;
}

.filterItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.clearButton {
  display: none;
}

.active {
  color: var(--link-color);
}

.active + .clearButton {
  display: block;
}

.clearIcon {
  width: 15px;
  height: 15px;
}

@media (min-width: 800px) and (orientation: landscape) {
  .host {
    width: 16rem;
    flex-flow: column nowrap;
    justify-content: initial;
    align-self: flex-start;
    position: sticky;
    top: 0;
  }

  .about {
    order: 1;
  }
}
