.article {
  font-size: 1.25rem;
}

.h1 {
  font-size: 1.8rem;
  line-height: 1.2em;
  white-space: normal;
  margin: 0;
}

.publishingInfo {
  margin-top: 0.5em;
  margin-bottom: 1.5em;
}

.article :global .image {
  max-height: 32rem;
  max-width: 100%;
}

.article a {
  text-decoration-line: underline;
}

.reviews,
.description {
  max-width: 70ch;
  margin-top: 2em;
  white-space: pre-line;
}

.reviews {
  text-align: right;
}

.reviews blockquote::before {
  content: open-quote;
}

.reviews blockquote::after {
  content: close-quote;
}

.reviews blockquote {
  font-size: 1.25em;
  text-align: left;
}

.amazon {
  line-height: 1.5;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.amazon a {
  border-bottom: dashed 1px;
}
