#root {
  --frame-padding: 2rem;
  --link-color: #ed6d72;
  min-height: 100vh;
}

main {
  padding: 0 var(--frame-padding) var(--frame-padding);
}

a {
  transition: color 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}

a:hover {
  color: var(--link-color);
}

@media (min-width: 800px) and (orientation: landscape) {
  #root {
    --frame-padding: 4rem;
    display: flex;
  }

  main {
    flex-grow: 1;
    padding-left: 0;
    padding-top: var(--frame-padding);
  }

  h1,
  h2 {
    white-space: pre-line;
  }
}
