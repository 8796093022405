* {
  box-sizing: border-box;
}

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Belleza', sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

ul,
button {
  margin: 0;
  padding: 0;
}

ul {
  list-style-type: none;
}

button {
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
}

button,
svg {
  display: block;
}
