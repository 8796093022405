#Grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
  gap: 2rem;
  align-items: stretch;
  justify-items: stretch;
  grid-auto-flow: dense;
}

@media (min-width: 105rem) {
  #Grid {
    grid-template-columns: repeat(auto-fill, minmax(18vw, 1fr));
  }
}

#Grid a {
  pointer-events: none;
}

@media (min-width: 42rem) {
  #Grid a {
    pointer-events: initial;
    grid-column: span var(--span, 1);
    display: grid;
    grid-template-columns: subgrid;
    position: relative;
  }

  #Grid .Image {
    position: absolute;
  }

  #Grid .square-placeholder {
    aspect-ratio: 1;
  }
}

#Grid .Image,
#Grid .Image .content,
#Grid .image {
  width: 100%;
  height: 100%;
}

#Grid .image {
  display: block;
  object-fit: cover;
}
