.host {
  /* the color transition on <a> couldn't work with the arrow transitions here */
  transition-property: none;
}

.host :global svg {
  display: inline-block;
  vertical-align: middle;
  width: 0.8rem;
  margin-left: 0.4rem;
  stroke: currentColor;
  overflow: visible;

  --ease-out: cubic-bezier(0.31, 0.52, 0.57, 1);
}

.host :global svg .arrow-line {
  transition: stroke-dasharray 0.2s var(--ease-out);
  stroke-dasharray: 8.48528;
}

.host :global svg .arrow-head {
  transition: transform 0.2s var(--ease-out), stroke-dasharray 0.2s var(--ease-out),
    stroke-dashoffset 0.2s var(--ease-out);
  stroke-dasharray: 8.36;
  stroke-dashoffset: 14.28;
  transform: translate(-17.85%, 17.85%);
}

.host:hover :global svg .arrow-line {
  stroke-dasharray: 12.03495;
}

.host:hover :global svg .arrow-head {
  stroke-dasharray: 13.16;
  stroke-dashoffset: 26.32;
  transform: unset;
}
