.section {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: var(--frame-padding);
}

.article {
  max-width: 26rem;
  font-size: 1.125rem;
  line-height: 1.25rem;
}

.img {
  margin-right: var(--frame-padding);
  max-width: calc(100% - var(--frame-padding));
  max-height: 14rem;
}

.em {
  font-style: normal;
  font-weight: normal;
  text-decoration: underline;
}
