.Image {
  position: relative;
}

/* disguise image to prevent download */
.Image .content:last-child .image,
.Image .content:last-child {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
}

.Image .image {
  user-select: none;
  display: block;
}
