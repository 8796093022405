#SingleImage > .content {
  display: flex;
  justify-content: space-between;
}

#SingleImage .back {
  display: block;
  height: var(--frame-padding);
}

#SingleImage article.Image {
  align-self: center;
}

#SingleImage .image {
  max-height: calc(99vh - 3 * var(--frame-padding));
  max-width: 100%;
}

#SingleImage .arrow {
  display: none;
}

@media (min-width: 42rem) {
  #SingleImage > .content {
    height: calc(99vh - 3* var(--frame-padding));
  }

  #SingleImage .arrow {
    display: flex;
    align-items: center;
  }

  #SingleImage .arrow .previous,
  #SingleImage .arrow .next {
    width: 30px;
    height: 30px;
    fill: none;
    color: inherit;
    stroke: currentColor;
    margin: 2em;
  }

  #SingleImage .arrow .previous {
    transform: scaleX(-1);
  }
}
