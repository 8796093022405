.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
  gap: 2rem;
  align-items: stretch;
  justify-items: stretch;
}

.grid :global .Image .image {
  display: block;
  width: 100%;
}

.title {
  font-size: 1.25rem;
  font-weight: normal;
  text-align: center;
}

@media (min-width: 105rem) {
  .grid {
    grid-template-columns: repeat(auto-fill, minmax(18vw, 1fr));
  }
}
